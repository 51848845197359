<template>
  <section class="full-height">

    <div class="columns is-multiline is-centered is-gapless is-marginless">      
      <div class="column is-10 is-offset-1 is-narrow mt2rem">
        <div v-if="companyData" class="has-text-grey is-size-5 is-size-4-mobile wa-underlined-title">
          {{ companyData.companies_id }} - {{ companyData.companies_name }} - {{ jsonPath }} - {{ runPath}} <br />
          JSON: {{ jsonTitle }} - {{ jsonNote }} <br />
          RUN: {{ runTitle }} - {{ runNote }}          
        </div>
      </div>
      <div class="column is-1 is-mobile is-narrow mt2rem">
        <b-button :type="'is-success'" class="smallbutton" @click="gotoJson">back</b-button> <br />
        <b-button :type="'is-success'" class="smallbutton" @click="editRunTitle">edit title</b-button> <br />
        <b-button :type="'is-success'" class="smallbutton" @click="editRunNote">edit note</b-button> <br />
        <b-button :type="'is-success'" class="smallbutton" @click="archiveRun">archive run</b-button> <br />
        <b-button :type="'is-success'" class="smallbutton" @click="deleteRun">delete run</b-button> <br />
        <b-button :type="'is-success'" class="smallbutton" @click="generateWPFile">gen wp file</b-button> <br />
        <b-button v-if="outputDataFile !== null" :type="'is-success'" class="smallbutton" @click="gotoOutputDataPreview">Preview</b-button> <br />
        
        <!-- <b-button :type="'is-success'" class="smallbutton">post process</b-button> -->
      </div>
      
    </div>
    
    <b-tabs v-model="activeTab" @input="tabChanged" type="is-toggle" expanded>
            
      <b-tab-item label="Input Json Files">
        <div v-if="jsonFiles" class="column is-12 is-mobile is-narrow mt2rem">
          
          <div v-for="(file, index) in jsonFiles" v-bind:key="`jsonFiles_${index}`" class="filesWrap" @click="viewInputJsonFile(file)">
            <b-button :type="'is-success'" class="smallbutton" @click.stop.prevent @click="downloadInputJsonFile(file)">download</b-button>
            {{ file.name }}            
          </div>
        </div>
      </b-tab-item>
      
      
      <b-tab-item label="Input Excel">
        <div class="column is-12 is-mobile is-narrow mt2rem" v-if="inputExcelData && activeTab === 1">
          
          <b-tabs type="is-boxed">
            <b-tab-item label="Users">
              Total Users: {{ inputExcelData.Users.length}}
              <b-table
                :data="inputExcelData.Users"
                :columns="getTableColumns(inputExcelData.Users, new Set(['roles']))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true">
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            <b-tab-item label="minmaxhours_main">
              Total minmaxhours: {{ inputExcelData.minmaxhours_main.length}}
              <b-table
                :data="inputExcelData.minmaxhours_main"
                :columns="getTableColumns(inputExcelData.minmaxhours_main, new Set([]))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true">
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            <b-tab-item label="minmaxhours_pref_code">
              Total minmaxhours_pref_code: {{ inputExcelData.minmaxhours_pref_code.length}}
              <b-table
                :data="inputExcelData.minmaxhours_pref_code"
                :columns="getTableColumns(inputExcelData.minmaxhours_pref_code, new Set([]))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            <b-tab-item label="Shifts">
              Total Shifts: {{ inputExcelData.Shifts.length}}
              <b-table
                :data="inputExcelData.Shifts"
                :columns="getTableColumns(inputExcelData.Shifts, new Set(['code_name','holiday_name']))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            
            <b-tab-item label="availability">
              Total availability: {{ inputExcelData.availability.length}}
              <b-table
                :data="inputExcelData.availability"
                :columns="getTableColumns(inputExcelData.availability, new Set([]))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            
            <b-tab-item label="assigned">
              Total assigned: {{ inputExcelData.assigned.length}}
              <b-table
                :data="inputExcelData.assigned"
                :columns="getTableColumns(inputExcelData.assigned, new Set([]))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            
            <b-tab-item label="blocked">
              Total blocked: {{ inputExcelData.blocked.length}}
              <b-table
                :data="inputExcelData.blocked"
                :columns="getTableColumns(inputExcelData.blocked, new Set([]))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            <b-tab-item label="blocked_days">
              Total blocked_days: {{ inputExcelData.blocked_days.length}}
              <b-table
                :data="inputExcelData.blocked_days"
                :columns="getTableColumns(inputExcelData.blocked_days, new Set(['blockDayName']))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            <b-tab-item label="holidays">
              Total holidays: {{ inputExcelData.holidays.length}}
              <b-table
                :data="inputExcelData.holidays"
                :columns="getTableColumns(inputExcelData.holidays, new Set(['name', 'type']))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            <b-tab-item label="codePairs">
              Total codePairs: {{ inputExcelData.codePairs.length}}
              <b-table
                :data="inputExcelData.codePairs"
                :columns="getTableColumns(inputExcelData.codePairs, new Set([]))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            <b-tab-item label="grouped_shifts">
              {{ inputExcelData.grouped_shifts.length}}
              Total grouped_shifts: {{ inputExcelData.codePairs.length}}
              <b-table
                :data="inputExcelData.grouped_shifts"
                :columns="getTableColumns(inputExcelData.grouped_shifts, new Set([]))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            <b-tab-item label="reserved_slots">
              Total reserved_slots: {{ inputExcelData.reserved_slots.length}}
              <b-table
                :data="inputExcelData.reserved_slots"
                :columns="getTableColumns(inputExcelData.reserved_slots, new Set([]))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
            <b-tab-item label="reserved_slots_details">
              Total reserved_slots_details: {{ inputExcelData.reserved_slots_details.length}}
              <b-table
                :data="inputExcelData.reserved_slots_details"
                :columns="getTableColumns(inputExcelData.reserved_slots_details, new Set([]))"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>
              </b-table>
            </b-tab-item>
            
          </b-tabs>
          
          
        </div>
      </b-tab-item>
      
      
      <b-tab-item label="Input run Files" v-if="runInputFiles && runInputFiles.length > 0">
        <div class="column is-12 is-mobile is-narrow mt2rem">
          <div v-for="(file, index) in runInputFiles" v-bind:key="`runInputFiles_${index}`" class="filesWrap" @click="viewInputRunFile(file)">
            <b-button :type="'is-success'" class="smallbutton" @click.stop.prevent @click="downloadInputRunFile(file)">download</b-button>
            {{ file.name }}            
          </div>
        </div>
      </b-tab-item>
      
      <b-tab-item label="Output run Files" v-if="runOutputFiles && runOutputFiles.length > 0">
        <div class="column is-12 is-mobile is-narrow mt2rem">
          <div v-for="(file, index) in runOutputFiles" v-bind:key="`runOutputFiles_${index}`" class="filesWrap" @click="viewInputRunOutputFile(file)">
            <b-button :type="'is-success'" class="smallbutton" @click.stop.prevent @click="downloadInputRunOutputFile(file)">download</b-button>
            {{ file.name }}            
          </div>
        </div>
      </b-tab-item>
      
      <b-tab-item label="OutputImages" v-if="runOutputFiles && runOutputFiles.length > 0">
        <div class="columns is-multiline is-centered is-gapless is-marginless">
          <div class="column is-11 is-offset-1 is-mobile is-narrow mt2rem">
            <div v-for="(file, index) in filterFiles(runOutputFiles, new Set(['png']))" v-bind:key="`runOutputFiles_${index}`">
              <img :src="`${API_ROOT}/maximus/company/${companyId}/buildRUN/viewOutput/${jsonPath}/run/${runPath}/${file.name}`" :alt="file.name" />
            </div>
          </div>
        </div>
      </b-tab-item>
      
      <b-tab-item label="Results" v-if="runOutputFiles && runOutputFiles.length > 0 && outputResultsData !== null">
        <div class="columns is-multiline is-centered is-gapless is-marginless">
          <div class="column is-12 is-mobile is-narrow mt2rem">
            <h1>output data</h1>
            <b-button :type="'is-success'" class="smallbutton" @click.stop.prevent @click="downloadInputRunOutputFile(outputResultsFile)">download</b-button>
            <b-table
                :data="outputResultsData"
                :columns="outputResultsColumns"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :mobile-cards="true"
                :paginated="true"
                :per-page="200"
                >
                
                <template #empty>
                    <div class="has-text-centered">No records</div>
                </template>

            </b-table>
          </div>
        </div>
      </b-tab-item>
      
      
    </b-tabs>
    
    <div v-if="fileContent !== null" class="column is-12 is-mobile is-narrow mt2rem">
      <b-field :label="fileContentName">
        <b-input maxlength="20000" type="textarea" :value="fileContent" custom-class="textareaHeightMainTemplate2b"></b-input>
      </b-field>
    </div>
    
    
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';
// import { API_ROOT } from '@/config/app.js';

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { WEB_ROOT, API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

// import { DDMMYYYY_HHii } from '@/helpers/dates.js';
const jsonFormat = require("json-format")

export default {
  name: "MaximusRun", 
  components: {
  },  
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      jsonBuildData       : [],
      tableIsLoading      : false,
      WEB_ROOT            : WEB_ROOT,
      API_ROOT            : API_ROOT,
      SITE_ROOT           : process.env.VUE_APP_SITE_MAIN,
      
      companyId           : null,
      jsonPath            : '',
      runPath             : '',
      companyData         : null,
      jsonTitle           : '',
      jsonNote            : '',
      runTitle            : '',
      runNote             : '',
      
      jsonFiles           : null,
      runInputFiles       : null,
      runOutputFiles      : null,
      inputExcelFile      : null,
      inputExcelData      : null,
      
      outputResultsFile     : null,
      outputResultsData     : null,
      outputResultsColumns  : null,
      
      outputDataFile : null,
      
      activeTab           : 0,
      
      fileContent         : null,
      fileContentName     : null,
    }
  },
  
  async mounted(){
    this.companyId  = this.$route.params.companyId;
    this.jsonPath   = this.$route.params.jsonPath;
    this.runPath    = this.$route.params.runPath;
    await this.getCompanyData()
    await this.getJSONRunData()
    
  },
  
  methods: {
    async getCompanyData() {
      this.ShowPageLoading(15000)
      let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/Simple`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.companyData  = response.data.payload.companyData
      
      this.HidePageLoading()
    },
    
    async getJSONRunData() {
      
      this.ShowPageLoading(15000)
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const runPath = encodeURIComponent(`${this.runPath}`)
      
      
      let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/view/${jsonPath}/${runPath}`, {});
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company json list', type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.jsonFiles  = response.data.payload.jsonFiles
      // console.info(`this.jsonFiles:`, this.jsonFiles)
      this.runInputFiles  = response.data.payload.runInputFiles
      // console.info(`this.runInputFiles:`, this.runInputFiles)
      this.runOutputFiles  = response.data.payload.runOutputFiles
      // console.info(`this.runOutputFiles:`, this.runOutputFiles)
      this.jsonTitle  = response.data.payload.jsonTitle
      this.jsonNote   = response.data.payload.jsonNote
      this.runTitle   = response.data.payload.runTitle
      this.runNote    = response.data.payload.runNote
      
      const findResultFile = response.data.payload.runOutputFiles.find(file => {return file.name.toLowerCase().indexOf(`_person_sol.csv`) > -1})
      // console.info(`findResultFile:`, findResultFile)
      if (findResultFile) {
        this.outputResultsFile = findResultFile
        // this.loadOutputResults(findResultFile.name)
      }
      
      const findOutputDataFile = response.data.payload.runOutputFiles.find(file => { return file.name.indexOf(`WP-run company_`) > -1 && file.name.indexOf(`.xlsx.json`) > -1 })
      if (findOutputDataFile) {
        this.outputDataFile = findOutputDataFile.name
      }
      
      
      const findExcelFile = response.data.payload.jsonFiles.find(file => {return file.name.toLowerCase().indexOf(`.xlsx.json`) > -1})
      // console.info(`findExcelFile:`, findExcelFile)
      if (findExcelFile) {
        this.inputExcelFile = findExcelFile
        // this.loadInputExcelFile(findExcelFile.name)
      }
      
      
      this.HidePageLoading()
    },
    
    async loadInputExcelFile(inputExcelFile) {
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const fileName = encodeURIComponent(inputExcelFile)
      
      // this.ShowPageLoading(15000)
      try {
        let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/view/${jsonPath}/${fileName}`);
        console.info(`response:`, response.data)
        this.inputExcelData = response.data
        
      } catch (err) {
        console.info(`Error loadInputExcelFile:`, err); 
      }
      // this.HidePageLoading()
    },
    getTableColumns(data, columnStrings = new Set([])) {
      console.info(`getTableColumns:`, data)
      if (data.length <=0) return []
      
      let columns = []
      for(let key in data[0]) {
        console.info(`key:`, key)
        if (columnStrings.has(key)) {
          columns.push({
            field: key,
            label: key,
            numeric: false,
            centered: false,
            sortable: true,
            searchable: true,
          })
        } else {
          columns.push({
            field: key,
            label: key,
            numeric: true,
            centered: true,
            sortable: true,
            searchable: true,
          })
        }
      }
      
      console.info(`columns:`, columns)
      return columns      
    },
    
    async loadOutputResults(outputResultsFile) {
      // console.info(`outputResultsFile:`, outputResultsFile)
      
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const runPath = encodeURIComponent(`${this.runPath}`)
      const fileName = encodeURIComponent(outputResultsFile)
      
      try {
        let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/viewOutput/${jsonPath}/run/${runPath}/${fileName}`);
        // console.info(`response:`, response.data)
        if (typeof(response.data) === "string") {
          // console.info(`toProcess: `, response.data)
          const tableData = this.processOutputData(response.data)
          this.outputResultsData = tableData.data
          this.outputResultsColumns = tableData.columns
        }
        
      } catch (err) {
        console.info(`Error viewJsonFile:`, err); 
      }
    },
    processOutputData(Outputdata) {
      if (!Outputdata || typeof(Outputdata) !== "string" || Outputdata.length <=100) return null
      
      let lines = Outputdata.split(`\n`)
      if (lines.length <2 ) return null
      let headerValues = []
      
      let data = []
      let columns = []
      // console.info(`lines:`, lines.length)
      let columnStrings = new Set(["role"])
      for(let lineNr in lines) {
        const line = lines[lineNr].trim()
        // console.info(`line-${lineNr}:`, line)
        if (parseInt(lineNr) === 0) {
          headerValues = line.split(`,`)
          for(let headerValue of headerValues) {
            if (columnStrings.has(headerValue)) {
              columns.push({
                field: headerValue,
                label: headerValue,
                numeric: false,
                centered: false,
                sortable: true,
                searchable: true,
              })
            } else {
              columns.push({
                field: headerValue,
                label: headerValue,
                numeric: true,
                centered: true,
                sortable: true,
                searchable: true,
              })
            }
          }
          continue
        }
        
        let lineValues = line.split(`,`)
        if (lineValues.length <=1) continue
        let obj = {}
        for(let colnr in lineValues) {
          if (!headerValues[colnr]) continue
          if (columnStrings.has(headerValues[colnr]) === true) {
            obj[headerValues[colnr]] = lineValues[colnr]
          } else if (!lineValues[colnr]) {
            obj[headerValues[colnr]] = lineValues[colnr]
          } else {
            obj[headerValues[colnr]] = parseFloat(lineValues[colnr])
            // obj[headerValues[colnr]] = lineValues[colnr]
          }
          
        }
        // console.info(`obj:`, obj)
        // return
        data.push(obj)
      }
      // console.info(`data:`, data)
      // console.info(`columns:`, columns)
      return { data, columns}      
    },
        
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    
    filterFiles(files, allowedExtentions = new Set(['xlsx'])){
      let rtn = []
      for(let file of files) {
        let ext = this.GetFileExtention(file.name.toLowerCase())
        // console.info(`ext:`, ext)
        if (allowedExtentions.has(ext) !== true) continue
        rtn.push(file)
      }
      
      return rtn
    },
    
    GetFileExtention(File, FailValue = "-"){
      if (typeof(File) !== "string" || File.indexOf(".") === -1) {
        return FailValue;
      }
      
      let ParseStr = File.toString(); //check if File contains folder paths ifso remove
      if (ParseStr.indexOf("/") !== -1) {
        let dirSplit = ParseStr.split("/");
        ParseStr = dirSplit[dirSplit.length -1]
      }
      
      const FileParts = ParseStr.split(".");
      const ext = FileParts[FileParts.length -1];
      if (ext.length > 4) {
        return FailValue
      }
      
      return ext;
    },
    
    
    async viewInputJsonFile(file) {
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const fileName = encodeURIComponent(file.name)
      const extention = this.GetFileExtention(file.name.toLowerCase())
      
      this.ShowPageLoading(15000)
      try {
        let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildJSON/view/${jsonPath}/${fileName}`);
        // console.info(`response:`, response)
        if (typeof(response.data) === "string") {
          this.fileContent = response.data
        } else if (extention === "json") {
          this.fileContent = jsonFormat(response.data)
        } else {
          console.info(`unknown content: ${file.name}`, jsonFormat(JSON.stringify(response.data)))
          this.fileContent = `unknown content: ${file.name}`
        }
        // console.info(`this.fileContent:`, this.fileContent)
        this.fileContentName = file.name 
      } catch (err) {
        console.info(`Error viewJsonFile:`, err); 
        this.fileContent = `Error to load the file content!`
        this.fileContentName = file.name 
      }
      
      this.HidePageLoading()
    },
    
    async downloadInputJsonFile(file) {
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const fileName = encodeURIComponent(file.name)
      
      const url = `${API_ROOT}/maximus/company/${this.companyId}/buildJSON/download/${jsonPath}/${fileName}`
      // console.info(url)
      
      // console.info(`url:`, url)
      window.open(url,'_blank');
      return false
    },
    
    async viewInputRunFile(file) {
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const runPath = encodeURIComponent(`${this.runPath}`)
      const fileName = encodeURIComponent(file.name)
      const extention = this.GetFileExtention(file.name.toLowerCase())
      
      this.ShowPageLoading(15000)
      try {
        let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/view/${jsonPath}/run/${runPath}/${fileName}`);
        // console.info(`response:`, response)
        if (typeof(response.data) === "string") {
          this.fileContent = response.data
        } else if (extention === "json") {
          this.fileContent = jsonFormat(response.data)
        } else {
          console.info(`unknown content: ${file.name}`, jsonFormat(JSON.stringify(response.data)))
          this.fileContent = `unknown content: ${file.name}`
        }
        // console.info(`this.fileContent:`, this.fileContent)
        this.fileContentName = file.name 
      } catch (err) {
        console.info(`Error viewJsonFile:`, err); 
        this.fileContent = `Error to load the file content!`
        this.fileContentName = file.name 
      }
      
      this.HidePageLoading()
    },
    
    async downloadInputRunFile(file) {
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const fileName = encodeURIComponent(file.name)
      const runPath = encodeURIComponent(`${this.runPath}`)
      
      const url = `${API_ROOT}/maximus/company/${this.companyId}/buildRUN/download/${jsonPath}/run/${runPath}/${fileName}`
      console.info(url)
      
      // console.info(`url:`, url)
      window.open(url,'_blank');
      return false
    },
    
    async viewInputRunOutputFile(file) {
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const runPath = encodeURIComponent(`${this.runPath}`)
      const fileName = encodeURIComponent(file.name)
      const extention = this.GetFileExtention(file.name.toLowerCase())
      
      this.ShowPageLoading(15000)
      try {
        let response = await axios_api.get(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/viewOutput/${jsonPath}/run/${runPath}/${fileName}`);
        // console.info(`response:`, response)
        if (typeof(response.data) === "string") {
          this.fileContent = response.data
        } else if (typeof(response.data) === "number") {
          this.fileContent = response.data
        } else if (extention === "json") {
          this.fileContent = jsonFormat(response.data)
        } else {
          console.info(`unknown content: ${file.name}`, jsonFormat(JSON.stringify(response.data)))
          this.fileContent = `unknown content: ${file.name}`
        }
        // console.info(`this.fileContent:`, this.fileContent)
        this.fileContentName = file.name 
      } catch (err) {
        console.info(`Error viewJsonFile:`, err); 
        this.fileContent = `Error to load the file content!`
        this.fileContentName = file.name 
      }
      
      this.HidePageLoading()
    },
    
    async downloadInputRunOutputFile(file) {
      const jsonPath = encodeURIComponent(`${this.jsonPath}`)
      const fileName = encodeURIComponent(file.name)
      const runPath = encodeURIComponent(`${this.runPath}`)
      
      const url = `${API_ROOT}/maximus/company/${this.companyId}/buildRUN/downloadOutput/${jsonPath}/run/${runPath}/${fileName}`
      console.info(url)
      
      // console.info(`url:`, url)
      window.open(url,'_blank');
      return false
    },
    
    tabChanged(){
      this.fileContent = null
      this.fileContentName = null
    },
    
    
    
    
    async gotoJson(){
      
    },
    
    async archiveRun(){
      this.$buefy.dialog.confirm({
        title: 'Archive Run',
        message: `Are you sure you want to ARCHIVE '${this.runPath}'`,
        trapFocus: true,
        onConfirm: () => this.archiveRunConfirm()
      })
    },
    
    async archiveRunConfirm(){
      
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/archive`, {
        jsonPath: this.jsonPath,
        runPath: this.runPath
      });
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to archive run ${this.runPath} list`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.HidePageLoading()
      this.gotoJson()
    },
    
    async deleteRun(){
      this.$buefy.dialog.confirm({
        title: 'Delete Run',
        message: `Are you sure you want to DELETE '${this.runPath}'`,
        trapFocus: true,
        onConfirm: () => this.deleteRunConfirm()
      })
    },
    
    async deleteRunConfirm(){
      
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/delete`, {
        jsonPath: this.jsonPath,
        runPath: this.runPath
      });
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to delete run ${this.runPath} list`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.HidePageLoading()
      this.gotoJson()
    },
    
    async generateWPFile(){
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/generateWP`, {
        jsonPath: this.jsonPath,
        runPath: this.runPath
      });
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to generte run ${this.runPath} list`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.HidePageLoading()
    },
    
    async editRunTitle(){
      this.$buefy.dialog.prompt({
        title: 'Edit Run Title',
        message: `Please enter new Run Title`,
        inputAttrs: {
          placeholder: '',
          value: this.runTitle,
          maxlength: 100000
        },
        trapFocus: true,
        onConfirm: (value) => this.editRunTitleConfirm(value)
      })
    },
    
    async editRunTitleConfirm(newTitle){
      if (newTitle === '') {
        return this.$buefy.dialog.alert({ title: 'Error', message: `invalid title: ${newTitle}`, type: 'is-danger' })
      }
      
      if (this.runTitle === newTitle) {
        return
      }
      
      console.info(`editTitleConfirm new title:`, newTitle)
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/editTitle`, {
        jsonPath: this.jsonPath,
        runPath: this.runPath,
        newContent: newTitle
      })
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to edit title of run  ${this.runPath}`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.HidePageLoading()
      
      this.getJSONRunData()
    },
    
    async editRunNote(){
      this.$buefy.dialog.prompt({
        title: 'Edit Run Note',
        message: `Please enter new Run Note`,
        inputAttrs: {
          placeholder: '',
          value: this.runNote,
          maxlength: 100000
        },
        trapFocus: true,
        onConfirm: (value) => this.editRunNoteConfirm(value)
      })
    },
    
    async editRunNoteConfirm(newNote){
      if (newNote === '') {
        return this.$buefy.dialog.alert({ title: 'Error', message: `invalid note: ${newNote}`, type: 'is-danger' })
      }
      
      if (this.runNote === newNote) {
        return
      }
      
      console.info(`editTitleConfirm new title:`, newNote)
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/company/${this.companyId}/buildRUN/editNote`, {
        jsonPath: this.jsonPath,
        runPath: this.runPath,
        newContent: newNote
      })
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to edit note of run  ${this.runPath}`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      this.HidePageLoading()
      
      this.getJSONRunData()
    },
    gotoOutputDataPreview(){
      const url = `${this.WEB_ROOT}/company/${this.companyId}/maximus/json/${this.jsonPath}/run/${this.runPath}/preview/${this.outputDataFile.replace(`.xlsx.json`, `.xlsx`)}`
      console.info(url)
      window.location.href = url
    }
    
  },
};
</script>

<style>
  .filesWrap:hover{
    color: blue;
    cursor: pointer;
  }
    
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
  
  .textareaHeightMainTemplate2b{
    /* background-color: red; */
    font-size: 14px;
    height: 1000px;
    max-height: 900px!important;
  }
  
  .smallbutton{
    padding: 2px;
    margin: 0px;
    margin-right: 10px;
    line-height: 20px;
    font-size: 12px;
    border: 1px solid #000;
    height: 22px;
  }
  .smallbutton:hover{
    color: black !important;
    border: 1px solid black;
  }

</style>